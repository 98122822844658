import React, { useRef, useState, useEffect } from "react";

import playIcon from "../../assets/icons/play-filled-orange.png";
import pauseIcon from "../../assets/icons/pause-filled-orange.png";

const CustomAudioPlayer = ({ src }) => {
  const playerRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audio = playerRef.current;

    const updateProgress = () => {
      const { currentTime, duration } = audio;
      setProgress((currentTime / duration) * 100);
    };

    audio.addEventListener("timeupdate", updateProgress);
    audio.addEventListener("ended", () => setIsPlaying(false));

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("ended", () => setIsPlaying(false));
    };
  }, []);

  const radius = 20;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  // Inline styles
  const styles = {
    svg: {
      transform: "rotate(-90deg)",
    },
    circleBackground: {
      fill: "none",
      stroke: "#FDEEEA",
      strokeWidth: "3",
    },
    circleForeground: {
      fill: "none",
      stroke: "#F05A30",
      strokeWidth: "3",
      strokeLinecap: "round",
      transition: "stroke-dashoffset 0.5s ease",
    },
  };

  return (
    <div className="flex relative w-10 h-10 md:w-10 md:h-10 lg:w-12 lg:h-12">
      <svg
        className="w-full h-full"
        viewBox="0 0 46 46"
        style={styles.svg}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="23" cy="23" r={radius} style={styles.circleBackground} />
        <circle
          cx="23"
          cy="23"
          r={radius}
          style={{
            ...styles.circleForeground,
            strokeDasharray: circumference,
            strokeDashoffset,
          }}
        />
      </svg>
      <div className="absolute inset-[9%] flex items-center justify-center bg-[#FDEEEA] rounded-full" onClick={handlePlayPause}>
        <img
          src={isPlaying ? pauseIcon : playIcon}
          className="w-3 h-3"
          alt="Speaker"
        />
      </div>
      <audio ref={playerRef} src={src} />
    </div>
  );
};

export default CustomAudioPlayer;
