import React, { useState, useRef, useEffect } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import EditIcon from "../assets/icons/edit.svg";

export default function InternationPhoneInput({ value, onChange }) {
  const [isEditable, setIsEditable] = useState(false);
  const inputRef = useRef(null);

  const handleEditClick = () => {
    console.log("clicked");
    setIsEditable(true);
  };

  const handleBlur = () => {
    setIsEditable(false);
  };

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditable]);

  return (
    <div
      className="relative flex w-full cursor-pointer"
      role="button"
      tabIndex={0}
      onClick={(e) => {
        e.preventDefault();
        setIsEditable(true);
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          setIsEditable(true);
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }
      }}
    >
      <PhoneInput
        ref={inputRef}
        defaultCountry="in"
        value={value}
        onChange={onChange}
        className="custom-phone-input w-full"
        style={{
          "--react-international-phone-background-color": "#EEEEEE",
          "--react-international-phone-text-color": "#44454E",
          "--react-international-phone-font-size": "14px",
          "--react-international-phone-height": "48px",
          "--react-international-phone-border-color": "#EEEEEE",
          "--react-international-phone-disabled-background-color": "#EEEEEE",
          "--react-international-phone-disabled-text-color": "#44454E",
          pointerEvents: "none",
        }}
        disabled={!isEditable}
        onBlur={handleBlur}
        disableDialCodeAndPrefix
      />
      <img
        className="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none"
        src={EditIcon}
        alt="edit"
      />
    </div>
  );
}
