import React, { useState, useEffect } from "react";
import { getSuggestedSpeechData } from "../../api/services/ai";
import { getTranscriptionData } from "../../api/services/transcribe";
import { useNavigate } from "react-router-dom";
import NewMediaPlayer from "../../components/NewMediaPlayer";
import starIcon from "../../assets/icons/star.png";
import CustomAudioPlayer from "../../components/Record/CustomAudioPlayer";

export default function TranscriptionReview() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("userId");
  const assessmentId = params.get("assessmentId");
  const flow = params.get("flow");
  const userType = params.get("userType") || params.get("usertype");

  const [suggestedSpeechData, setSuggestedSpeechData] = useState(null);
  const [suggestedSpeechUrl, setSuggestedSpeechUrl] = useState(null);
  const [originalSpeechUrl, setOriginalSpeechUrl] = useState(null);
  const [speechProvider, setSpeechProvider] = useState(null);
  const [transcriptionData, setTranscriptionData] = useState(null);
  const [error, setError] = useState(false); // State for tracking errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const suggestedData = await getSuggestedSpeechData(
          userId,
          assessmentId,
          userType
        );
        const originalData = await getTranscriptionData(
          userId,
          assessmentId,
          userType
        );
        setSuggestedSpeechData(suggestedData);
        setTranscriptionData(originalData.transcription);
        setSuggestedSpeechUrl(suggestedData.suggested_speech_url);
        setOriginalSpeechUrl(suggestedData.original_speech_url);
        setSpeechProvider(suggestedData?.speechprovider);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(true);
      }
    };

    fetchData();
  }, []);

  const handleNavigate = () => {
    const url = `/ai-assessment/?userId=${userId}&assessmentId=${assessmentId}&userType=${userType}&flow=${flow}`;
    navigate(url, { state: { indext: 1 } });
  };

  const handlePlay = () => {
    console.log("speechProvider:", speechProvider);
    if (speechProvider === "azure-personalvoice") {
      console.log("setting");
      localStorage.setItem("PVPlayed", "true");
    }
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "trackMixpanelEvent",
        meta: { action: "SUGGESTED_SPEECH_VOICE" },
      })
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen pt-10 space-y-8">
      <div className="flex items-center justify-between w-full px-8">
        <div className="relative flex align-middle justify-center w-full">
          <h1 className="text-2xl font-semibold text-darkgray">
            Transcription Review
          </h1>
          <img
            className="h-9 w-9 absolute left-0 cursor-pointer"
            alt="cross"
            src={require("../../assets/icons/arrow-left-outlined-grey.png")}
            onClick={handleNavigate}
          />
        </div>
      </div>
      {error ? (
        <div className="flex items-center justify-center w-full h-full">
          <p className="text-base text-darkgray w-2/3 text-center">
            There seems to be an issue in the section. You can report it at{" "}
            <a href="mailto:care@vani.coach" className="text-primary">
              care@vani.coach
            </a>
          </p>
        </div>
      ) : (
        <div
          className="flex-1 w-full flex flex-col space-y-4"
          style={{ height: "calc(100% - 10rem)" }}
        >
          <div className="flex flex-row items-center justify-between mx-8">
            <h1 className="uppercase text-xl font-bold text-darkgray tracking-wide">
              Original
            </h1>
            {originalSpeechUrl && <CustomAudioPlayer src={originalSpeechUrl} /> }
          </div>
          <div
            className="flex-none overflow-auto mx-8 space-y-2 pb-4"
            style={{ height: "40%" }}
          >
            <div className="flex flex-col sticky top-0 bg-white z-10 space-y-2">
              {suggestedSpeechData &&
                suggestedSpeechData?.filler_count > 0 &&
                suggestedSpeechData?.common_fillers && (
                  <div className="flex space-x-2 items-center py-3 px-5 bg-[#FBD6CB] rounded-[10px]">
                    <div className="flex space-x-2 items-center">
                      <p className="text-darkBlue2 font-sans text-sm">
                        Filler Count:
                      </p>
                      <p className="text-darkgray font-bold font-sans text-base">
                        {suggestedSpeechData.filler_count}
                      </p>
                    </div>
                    <p className="text-primary font-sans text-base">|</p>
                    <div className="flex space-x-2 items-center">
                      <p className="text-darkBlue2 font-sans text-sm">
                        Top Fillers:
                      </p>
                      <span className="text-darkgray font-bold font-sans text-base">
                        {suggestedSpeechData.common_fillers.join(", ")}
                      </span>
                    </div>
                  </div>
                )}
              {suggestedSpeechData &&
                suggestedSpeechData?.filler_count === 0 && (
                  <div className="flex space-x-2 items-center py-3 px-5 bg-[#FBD6CB] rounded-[10px]">
                    <img src={starIcon} alt="star" style={{ width: "18px" }} />
                    <p className="text-darkgray font-bold font-sans text-base">
                      No Fillers Detected! Impressive Practice!
                    </p>
                  </div>
                )}
            </div>
            {transcriptionData && (
              <p className="text-base text-darkgray leading-7 font-sans">
                {transcriptionData.split(" ").map((word, index) => {
                  const cleanWord = word.toLowerCase().replace(/[^\w\s]/g, "");
                  const normalizedFillers = suggestedSpeechData.fillers.map(
                    (filler) => filler.toLowerCase()
                  ); // Normalize fillers
                  const isFiller = normalizedFillers.includes(cleanWord);
                  return (
                    <span
                      key={index}
                      style={{
                        backgroundColor: isFiller ? "#FBD6CB" : "transparent",
                        borderRadius: isFiller ? "5px" : "0",
                        padding: isFiller ? "1px 4px" : "0",
                      }}
                    >
                      {word}{" "}
                    </span>
                  );
                })}
              </p>
            )}
          </div>
          {suggestedSpeechData && suggestedSpeechData.suggested_speech && (
            <div
              className="flex-none bg-green3 px-8 rounded-t-2xl overflow-auto space-y-2"
              style={{ height: "60%" }}
            >
              <div className="sticky top-0 bg-green3 z-10 py-2">
                <div className="flex space-x-2 items-center mt-8">
                  <img
                    className="h-5 w-5"
                    alt="tick"
                    src={require("../../assets/icons/tick-filled-green.png")}
                  />
                  <h1 className="uppercase text-xl font-semibold text-darkgray tracking-wide leading-5">
                    Suggested
                  </h1>
                </div>
              </div>
              {suggestedSpeechUrl && (
                <div className="w-full">
                  <NewMediaPlayer
                    url={suggestedSpeechUrl}
                    onPlay={handlePlay}
                  />
                </div>
              )}
              <p className="text-base text-darkgray leading-7 font-sans">
                {suggestedSpeechData.suggested_speech}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
